.loading-dots {
  text-align: center;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
}

.loading-dots--dot {
  animation: dot-keyframes 2.5s infinite ease-in-out;
  background-color: #000;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 2px;
  margin-right: 2px;

}

.loading-dots--dot:nth-child(2) {
  animation-delay: .5s;
}

.loading-dots--dot:nth-child(3) {
  animation-delay: 0.75s;
}

.loading-dots--dot:nth-child(4) {
  animation-delay: 1s;
}

.loading-dots--dot:nth-child(5) {
  animation-delay: 1.25s;
}
