.header .nav-item.dropdown .dropdown-menu,
.header .dropdown .dropdown-menu {
    margin-top: 0 !important;
}

.navbar-brand svg {
    width: 100px;
    height: auto;
}

.athena-logo svg {
    width: 108px;
    height: auto;
}