/* viewport height classes */
.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.form-field-height {
  height: 80px !important;
}

.popover {
  z-index: 1019;
  min-width: 280px !important;
}

.popover-arrow-positioned-end .popover-arrow {
  left: auto !important;
  right: 13px !important;
  transform: translate(0px, 0px) !important;
}

.monospace-font {
  font-family: monospace, "Courier New", Courier !important;
}

.light-grey-bg {
  background-color: #f3f3f3;
}

.blue-color {
  color: blue;
}

.cursor-pointer {
  cursor: pointer !important;
}

.serverExportModalHeight {
  height: 350px !important;
}

/* apply primary color to login & forgot pages */
.global-bg .login-page,
.global-bg .forgot-page {
  background: rgba(var(--primary-color), 0.1);
}

/* outline primary button  */
.app-outline-primary {
  border-color: rgba(var(--primary-color), 1);
}

.app-outline-primary:hover {
  background-color: rgba(var(--primary-color), 1);
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

/* ---------------------- global styles ----------------------  */

/* importing Outfit from google fonts */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap");

/* app primary color dynamic */
.app-primary-color {
  color: rgba(var(--primary-color), 1) !important;
}

/* app primary color static */
.app-primary-color-static {
  color: rgba(6, 110, 210, 1) !important;
}

/* app secondary color static */
.app-secondary-color-static {
  color: rgba(248, 248, 248, 1) !important;
}

/* app secondary background color dynamic */
.app-secondary-bg-color-static {
  background: rgba(248, 248, 248, 1) !important;
}

/* app primary background color dynamic */
.app-primary-bg-color,
.settings-page .nav-pills .nav-link.active {
  background: rgba(var(--primary-color), 1) !important;
}

/* app secondary background color dynamic */
.app-secondary-bg-color {
  background: rgba(var(--secondary-color), 1) !important;
}

/* app primary background color dynamic for 10% */
.app-primary-bg-color-10 {
  background: rgba(var(--primary-color), 0.1) !important;
}

.app-primary-bg-color:hover,
.app-primary-bg-color.btn-check:focus + .btn-outline-secondary,
.app-primary-bg-color.btn-outline-secondary:focus,
.app-primary-color button:focus:not(:focus-visible) {
  background: rgba(var(--primary-color), 0.9) !important;
}

.page-tittle {
  font-size: 1.5rem;
  font-weight: 700;
}

.fs-18 {
  font-size: 1.125rem;
  /* fontsize - 18px  */
}

.fs-14 {
  font-size: 0.875rem;
  /* fontsize - 14px  */
}

.fs-12 {
  font-size: 0.75rem;
  /* fontsize - 12px  */
}

/* modal customizing styles */

.app-modal-header,
.app-card-header {
  background-color: rgba(222, 239, 255, 1);
}

.app-card-footer .app-card-submit,
.toggle-active,
.modal-submit-btn {
  background-color: rgba(6, 110, 210, 1) !important;
  color: #fff !important;
}

/* Change select dropdown color to secondary color from pages */
.dropdown-color-secondary-from-pages .select-drop-down .react-select__control,
.dropdown-color-secondary-from-pages .react-select__control {
  background: rgba(var(--secondary-color), 1) !important;
}

.placeholder-transperancy .react-select__placeholder {
  opacity: 0.5 !important;
}

.app-btn-height {
  height: 48px !important;
}

.graph-height {
  height: 330px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.graph-width {
  overflow-x: scroll;
  overflow-y: hidden;
}

/* custom modal width */

.modal-90w {
  max-width: 90% !important;
}

/* dynamic accordion toggle css  */

.accpordion-toggle-list .accordion-button,
.comment-card,
.settings-page .nav-pills .nav-link {
  background-color: #fff !important;
}

.accpordion-toggle-list .active {
  background: rgba(var(--primary-color), 1) !important;
  color: #fff !important;
}

.graph-width {
  overflow-x: scroll;
  overflow-y: hidden;
}

.graph-height {
  height: 420px;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* accordion arrow btn position styles */

.accordion-arrow-btn {
  position: absolute !important;
  right: 0;
  top: 50%;
  width: auto;
  padding: 6px;
  transform: translateY(-50%);
}
